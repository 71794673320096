define(['app'], function (app) {
  const customerService = () => {
    const component = {};

    const _config = {
      selectors: {
        subjectSelect: "#queryCategoryId",
        disclaimerText: '.disclaimerText'
      }
    }

    const _init = (element) => {
      component.element = element;
      component.subjectSelect = document.querySelector(component.config.selectors.subjectSelect);
      component.disclaimerText = document.querySelector(component.config.selectors.disclaimerText);
      component.bind();
    };

    const _bind = () => {
      if(document.querySelector(component.config.selectors.subjectSelect)) {
        component.subjectSelect.addEventListener("change", component.contactSubjectChange);
      }
    };

    const _contactSubjectChange = () => {

      var selectedValue = component.subjectSelect.options[component.subjectSelect.selectedIndex].value;
      if (selectedValue == '78') {
        component.disclaimerText.classList.remove("hidden");
      } else {
        component.disclaimerText.classList.add("hidden");
      }
    };

    component.init = _init;
    component.config = _config;
    component.bind = _bind;
    component.contactSubjectChange = _contactSubjectChange;
    return component;
  };

  return customerService;
});
